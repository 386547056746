<template>
  <div>
    <v-card>
      <v-toolbar dense flat dark color="#093E4C">
        <v-toolbar-title>
          <span>Leads para marcación</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data"
          :loading="loading"
          no-data-text="Sin leads para mostrar"
          :footer-props="{
            'items-per-page-all-text': 'Todos',
            'items-per-page-text': 'Elementos por página',
          }"
        >
          <template
            v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
          >
            <div v-if="itemsLength">
              Mostrando {{ pageStart }} - {{ pageStop }} de
              {{ itemsLength }} leads
            </div>
          </template>
          <template v-slot:item.call="{ item }">
            <v-btn icon small>
              <v-icon small color="green" @click="changeRoute(item)">
                mdi-phone
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mainAxios } from "../../mainAxios";
import moment from "moment";

export default {
  props: ["bus"],

  data() {
    return {
      headers: [
        {
          text: "Ramo",
          align: "start",
          value: "ramo",
        },
        {
          text: "Productos",
          align: "center",
          value: "producto",
        },
        {
          text: "Status",
          align: "center",
          value: "status",
        },
        {
          text: "Leads",
          align: "center",
          value: "lead",
        },
        {
          text: "Fecha de creación",
          align: "center",
          value: "fecha_lead",
        },
        {
          text: "Llamar",
          align: "end",
          value: "call",
        },
      ],
      data: [],
      loading:false
    };
  },
  mounted() {
    this.getLeadsMarcacion();
    this.bus.$on("reload", this.getLeadsMarcacion);
  },
  beforeDestroy() {
    clearInterval(this.intervalo);
  },
  methods: {
    checkStatus(){
        window.bpspat.api.getState(this.statusChecked)
    },
    statusChecked(state){
      if(state.data.status == 'ready'){
        if(this.data.length > 0 ){
          var currentItem = this.data[0]
          currentItem.marcacion_automatica = true
          this.changeRoute(currentItem)
        }
      }
    },
    getLeadsMarcacion() {
      this.data = [];
      this.loading = true
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("/v1/lead-segurify/listaMarcacion", config)
        .then((response) => {
          if(response.data[0].error == null){
            response.data.sort((a, b) => {
              const milisA = new Date(a.fecha_lead).getTime();
              const milisB = new Date(b.fecha_lead).getTime();
              if (milisA < milisB) return 1;
              if (milisB < milisA) return -1;
              return 0;
            }).map((element) => {
              let jsonFormat = element.request_json ? JSON.parse(element.request_json) : {};
              this.data.push({
                fecha_lead: element.fecha_lead ?? ''
                  ? moment.utc(element.fecha_lead).format("DD/MM/YYYY")
                  : "No Disponible",
                ramo: element.ramo ? element.ramo : '',
                producto: element.producto ? element.producto : '',
                status: element.status ? element.status : '',
                lead:
                  jsonFormat.nombre +
                  " " +
                  jsonFormat.apellidopaterno +
                  " " +
                  jsonFormat.apellidomaterno,
                uuid_lead_segurify: element.uuid_lead_segurify ? element.uuid_lead_segurify : '',
                uuid: element.uuid ? element.uuid : '',
                telefono: element.telefono ? element.telefono : '',
                servicio_embebido: element.servicio_embebido ? element.servicio_embebido : null,
                id: element.id ? element.id : '',
                embedded: true,
                marcacion_automatica:false
              });
            });
          }
          this.loading = false
        });
    },
    changeRoute(item) {
      this.$router.push('/segurify/leads-segurify/lead/'+item.uuid_lead_segurify);
    },
  },
};
</script>
